<template>
  <div class="tariffs-wrapper">

    <b-notification class="notification-wrapper" :active.sync="notification.show" :type="notification.type" auto-close>
      {{ notification.text }}
    </b-notification>

    <div class="tariffs-header-panel">
      <router-link class="new-tariff" :to="{ name: 'TariffNew' }">
        <b-button type="is-primary">
          Создать
        </b-button>
      </router-link>
    </div>

    <div class="tariffs-body-content">

      <div class="table-wrapper">

        <b-field type="text" class="table-search search-region">
          <b-input v-model="search_region" @input="onSearchRegion" placeholder="Поиск по региону"></b-input>
        </b-field>

        <b-dropdown v-model="search_currency" class="dropdown-wrapper" aria-role="list">
          <template slot="trigger">
            <div class="select-input">
              {{ search_currency && search_currency.length > 0 ? search_currency : 'Поиск по валюте' }}
            </div>
          </template>
          <b-dropdown-item aria-role="listitem" :value="currency" @click="onSearchCurrency" v-for="(currency, index) in ['', 'RUB', 'EUR', 'USD']" :key="index">
            {{ currency }}
          </b-dropdown-item>
        </b-dropdown>

        <b-field type="text" class="table-search">
          <b-input v-model="search" @input="onSearch" placeholder="Поиск по названию"></b-input>
        </b-field>

        <b-table :data="tariffs" :loading="isLoading" 
            paginated
            backend-pagination
            :total="total_pages"
            :current-page.sync="request_params.page"
            :per-page="request_params.per_page"
            @page-change="onPageChange"

            backend-sorting
            :default-sort-direction="request_params.sort_direction"
            :default-sort="[request_params.sort_field, request_params.sort_direction]"
            @sort="onSort"
          >
            <template slot-scope="props">
                <b-table-column field="name" label="Название" sortable>
                  <router-link class="new-tariff" :to="{ name: 'TariffEdit', params: { tariff_id: props.row.id } }">
                    {{ props.row.name }}
                  </router-link>
                </b-table-column>

                <b-table-column field="price" label="Цена">
                  {{ props.row.price }}
                </b-table-column>

                <b-table-column field="currency" label="Валюта" centered>
                  {{ props.row.currency }}
                </b-table-column>

                <b-table-column field="region" label="Регион" centered>
                  {{ props.row.region }}
                </b-table-column>

                <b-table-column field="time_added" label="Дата создания" sortable centered>
                  {{ formatDate(props.row.time_added) }}
                </b-table-column>

                <b-table-column field="time_added" label="Удалить" centered>
                  <b-button type="is-danger" icon-right="delete" @click="deleteTariff(props.row.id)"/>
                </b-table-column>
            </template>
            <template slot="empty">
              <div class="content has-text-grey has-text-centered empty-table" v-if="!isLoading">
                {{ $t('no_data') }}
              </div>
            </template>
        </b-table>
      </div>
    </div>

    <b-modal :active.sync="show_delete_modal" @close="deleting_id = null">
      <div class="card">
        <div class="card-content">
          <div class="delete-modal-wrapper">
            <div class="delete-modal-text">
              Вы уверены что хотите удалить тариф?
            </div>
            <div class="buttons-wrapper">
              <b-button type="is-primary" @click="deleting_id = null; show_delete_modal = false">
                Отмена
              </b-button>

              <b-button type="is-danger" @click="deleteRequest">
                Удалить
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export default {
  name: 'Tariffs',
  data () {
    return {
      isLoading: false,
      tariffs: [],
      request_params: {
        page: 1,
        per_page: 10,
        sort_field: 'name',
        sort_direction: 'asc',
      },
      total_pages: 10,
      search: '',
      search_timer: null,
      search_region: '',
      search_currency: '',
      deleting_id: null,
      show_delete_modal: false,
      notification: {
        show: false,
        text: '',
        type: 'is-success'
      },
    }
  },
  mounted () {
    if(!this.$store.getters.tariffs || this.$store.getters.tariffs.length === 0 || 
      !this.$store.getters.get_tariffs_pagination) {
      this.taraffisRequest()
    }
    else {
      this.tariffs = this.$store.getters.tariffs
      this.request_params.page = this.$store.getters.get_tariffs_pagination.current_page
      this.total_pages = this.$store.getters.get_tariffs_pagination.total_pages * this.request_params.per_page
      if(this.$store.getters.get_tariffs_pagination.search) {
        this.search = this.$store.getters.get_tariffs_pagination.search
      }
      if(this.$store.getters.get_tariffs_pagination.region) {
        this.search_region = this.$store.getters.get_tariffs_pagination.region
      }
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    taraffisRequest () {
      this.isLoading = true
      this.$store.dispatch('tariffs', {
        vm: this,
        callback: this.tariffsCallback
      })
    },
    tariffsCallback (response) {
      if(response === 'error') {
        this.notification.type = 'is-danger'
        this.notification.text = 'Ошибка получения тарифов'
        this.notification.show = true
      }
      else {
        this.tariffs = response.tariffs
        this.total_pages = response.pagination.total_pages * this.request_params.per_page
      }

      this.isLoading = false
    },
    onPageChange (page) {
      this.request_params.page = page
      this.taraffisRequest()
    },
    onSort (field, order) {
      this.request_params.sort_field = field
      this.request_params.sort_direction = order
      this.taraffisRequest()
    },
    onSearch () {
      clearTimeout(this.search_timer)
      this.search_timer = null

      this.search_timer = setTimeout(function () {
        if(this.search.length > 0) {
          this.request_params.search = this.search
        }
        else {
          delete this.request_params.search
        }
        this.taraffisRequest()
      }.bind(this), 1000)
    },
    onSearchRegion () {
      clearTimeout(this.search_timer)
      this.search_timer = null

      this.search_timer = setTimeout(function () {
        if(this.search_region.length > 0) {
          this.request_params.region = this.search_region
        }
        else {
          delete this.request_params.region
        }
        this.taraffisRequest()
      }.bind(this), 1000)
    },
    onSearchCurrency () {
      if(this.search_currency.length > 0) {
        this.request_params.currency = this.search_currency
      }
      else {
        delete this.request_params.currency
      }
      this.taraffisRequest()
    },

    deleteTariff (id) {
      this.deleting_id = id
      this.show_delete_modal = true
    },
    deleteRequest () {
      this.show_delete_modal = false
      this.isLoading = true
      this.$store.dispatch('delete_tariff', {
        vm: this,
        callback: this.deleteRequestCallback
      })
    },
    deleteRequestCallback (request_status, response) {
      if(request_status === 'error') {
        this.notification.type = 'is-danger'
        this.notification.text = 'Ошибка удаления тарифа'
        this.notification.show = true

        this.isLoading = false
      }
      else if(request_status === 'success') {
        this.request_params.page = 1
        this.taraffisRequest()

        this.notification.type = 'is-success'
        this.notification.text = 'Тариф успешно удален'
        this.notification.show = true
      }
      
    }
  }
}
</script>

<style lang="scss">
.tariffs-wrapper {

  .tariffs-header-panel {
    margin-bottom: 20px;

    .new-tariff {
      height: fit-content;
      display: inline-block;
    }
  }
  .tariffs-body-content {

    .table-wrapper {

      .table-search {
        width: 250px;
      }
      .dropdown-wrapper {
        margin-bottom: 15px;

        .dropdown-trigger {
          width: 250px;
        }
        .dropdown-content {

          .dropdown-item {
            height: 33px;
          }
        }
      }
      .search-region {
        margin-bottom: 15px !important;
      }
      .empty-table {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .modal-content {
    width: 500px !important;

    .delete-modal-wrapper {
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .delete-modal-text {
        text-align: center;
        font-size: 18px;
      }
      .buttons-wrapper {
        display: flex;
        justify-content: center;

        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>